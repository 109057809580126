import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import useRequest from 'core/hooks/useRequest';

import { VOID_QUERY } from 'core/site/constants';

import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import { isMotoPath } from 'site/utils';

export const ComparePageContext = createContext();


function ComparePageProvider({ location: { pathname, search }, children }) {
  const { uuid: searchUuids } = queryString.parse(search);

  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';

  const [differentState, toggleDifferent] = useState(false);

  const { data: comparisons, isLoading: comparisonsAreLoading } = useRequest(
    search ? {
      queryKey: ['servicesApi', isMoto ? 'getMotoCompareData' : 'getCompareData', {
        modifications: [].concat(searchUuids).join(),
        releases: [].concat(searchUuids).join(),
        'attributes[car_modification]': 'base,options,specifications,ownership',
        'relations[car_modification]': 'release,completion',
        'relations[moto_release]': 'release',
        [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats,media',
        include: `${vehiclePrefix}_release,${vehiclePrefix}_modification_completion`,
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
        .then(({ data, included }) => {
          const content = isMoto ? 'releases' : 'modifications';

          return {
            merged: data.merged,
            [content]: denormalizeData({ data: data[content], included }),
          };
        }),
      enabled: process.env.BROWSER_RUNTIME,
    } : VOID_QUERY);

  useEffect(() => {
    /**
     * Сбрасываем флаг "Только различающиеся" если в сравнении остается одна модификация.
     * При добавлении модификаций, переключатель оставляем со своим состоянием.
     */
    if (typeof searchUuids === 'string') {
      toggleDifferent(false);
    }
  }, [searchUuids]);

  return (
    <ComparePageContext.Provider
      value={{
        comparisons,
        differentState,
        toggleDifferent,
        isLoading: comparisonsAreLoading,
        isMoto,
      }}
    >
      {children}
    </ComparePageContext.Provider>
  );
}

ComparePageProvider.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
  }),
};

export default withRouter(ComparePageProvider);
