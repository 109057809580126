import { useContext } from 'react';

import useRequest from 'core/hooks/useRequest';

import { VOID_QUERY } from 'core/site/constants';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { GeoContext } from 'site/components/GeoContext';

import { generateSelectOptions } from 'site/utils/forms';

import { servicesApi } from 'site/api/definitions/services';

import { RANGES } from './constants';


const getFormOptions = (dictionaries = {}, brands = []) => ({
  filteredBrands: generateSelectOptions(brands, { value: 'attributes.slug' }),
  filteredTypes: generateSelectOptions(dictionaries.type, { value: 'attributes.slug' }),
  filteredClasses: generateSelectOptions(dictionaries.class, { value: 'attributes.slug' }),
  filteredConfigurate: generateSelectOptions(dictionaries.configurate, { label: 'title', value: 'value' }),
  filteredTransmission: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
  filteredTransfer: generateSelectOptions(dictionaries.transfer, { label: 'title', value: 'value' }),
  filtiredCooling: generateSelectOptions(dictionaries.cooling, { label: 'title', value: 'value' }),
  filteredAbs: generateSelectOptions(dictionaries.abs, { label: 'title', value: 'value' }),
  filteredConditions: generateSelectOptions(dictionaries.condition, { label: 'title', value: 'value' }),
  filteredControl: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
});

/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const { geoSlug } = useContext(GeoContext);

    const {
      match: {
        params: {
          brand: brandSlug,
          geo,
        },
      },
    } = props;

    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', 'getMotoOffersDictionaries'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getMotoBrands', { 'filter[with_offers_from_geo]': geo || geoSlug }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        }, brandSlug ? {
          queryKey: ['servicesApi', 'getMotoModels', {
            'filter[with_offers_from_geo]': geo || geoSlug,
            'filter[brand]': brandSlug,
          }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        } : VOID_QUERY, {
          queryKey: ['servicesApi', 'getMotoOffersGeos', { 'filter[brand]': brandSlug }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        dictionaries = {},
        brands = [],
        models = [],
        geos = [],
      ],
      isLoading,
    } = processQueryResults(results);

    const formData = {
      formOptions: getFormOptions(dictionaries, brands),
      ranges: {
        ...dictionaries.ranges,
        ...RANGES,
        year: dictionaries.ranges?.year || RANGES.year,
        price: dictionaries.ranges?.price || RANGES.price,
      },
    };

    return (
      <Component
        {...props}
        formData={formData}
        isLoading={isLoading}
        models={models}
        geos={geos}
      />
    );
  };
}
