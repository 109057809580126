import { Fragment } from 'react';

import bindProps from 'core/components/bindProps';

import SecondaryMenu from 'site/components/SecondaryMenu';

import DealersForm from 'site/forms/Dealers';
import ReviewsForm from 'site/forms/Reviews';

import CatalogLayout from 'site/layouts/CatalogLayout';
import MotoCatalogLayout from 'site/layouts/MotoCatalogLayout';
import ReviewLayout from 'site/layouts/ReviewLayout';
import MotoOffersLayout from 'site/layouts/MotoOffersLayout';
import OffersLayout from 'site/layouts/OffersLayout';

import Search from 'site/pages/search';
import Journal from 'site/pages/journal';
import Rubric from 'site/pages/rubric';
import Tag from 'site/pages/tag';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import Dealer from 'site/pages/dealer';
import Offer from 'site/pages/offer';
import Dealers from 'site/pages/dealers';
import Compare from 'site/pages/compare';
import MotoReleasePhoto from 'site/pages/motoReleasePhoto';
import Firsttime from 'site/pages/firsttime';
import Development from 'site/pages/development';
import Geo from 'site/pages/geo';
import Pdd from 'site/pages/pdd';
import PddExamPage from 'site/pages/pdd/exam';
import PddTrenPage from 'site/pages/pdd/tren';
import PddMarafonPage from 'site/pages/pdd/marafon';

import Modification from 'site/pages/modification';
import ReleasePhoto from 'site/pages/releasePhoto';
import ReleaseFeatures from 'site/pages/releaseFeatures';
import ReleaseFeature from 'site/pages/releaseFeature';
import ReleaseColors from 'site/pages/releaseColors';
import Reviews from 'site/pages/reviews';
import BestChoice from 'site/pages/bestChoice';
import BestChoiceSelection from 'site/pages/bestChoiceSelection';


import {
  JOURNAL_URL,
  DEALER_URL,
  MOTO_URL,
} from 'site/constants';

const Vitrine = bindProps({ title: 'Авто объявления — лучшие автомобильные объявления, авто предложения, скидки и акции на автомобили, выгодные кредиты, сервис и другие автомобильные предложения.' })(Development);
const basicSlug = '[a-z0-9-_]+';
const numericSlug = '[0-9]+';

export default [
  {
    exact: true,
    path: JOURNAL_URL,
    component: Journal,
  }, {
    exact: true,
    path: `${JOURNAL_URL}/authors`,
    component: Authors,
  }, {
    exact: true,
    path: `${JOURNAL_URL}/authors/:level_2(${basicSlug})`,
    component: Author,
  }, {
    path: `${JOURNAL_URL}/search`,
    component: Search,
  }, {
    exact: true,
    path: `${JOURNAL_URL}/:level_1(${basicSlug})`,
    component: Rubric,
  }, {
    exact: true,
    path: `${JOURNAL_URL}/label/:level_2(${basicSlug})`,
    component: Tag,
  }, {
    exact: true,
    path: '/firsttime',
    component: Firsttime,
    puids: { puid6: 'QUTO_OTHER', puid18: 'other' },
  }, {
    exact: true,
    path: '/bestchoice',
    render: props => (
      <Fragment>
        <SecondaryMenu section='bestchoice' />
        <BestChoice {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: '/:moto(moto)/bestchoice',
    render: props => (
      <Fragment>
        <SecondaryMenu section='bestchoice' />
        <BestChoice {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: '/:lcv(lcv)/bestchoice',
    render: props => (
      <Fragment>
        <SecondaryMenu section='bestchoice' />
        <BestChoice {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/bestchoice/:slug(${basicSlug})`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='bestchoice' />
        <BestChoiceSelection {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:moto(moto)/bestchoice/:slug(${basicSlug})`,
    render: props => (
      <Fragment>
        <SecondaryMenu />
        <BestChoiceSelection {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/bestchoice/:slug(${basicSlug})`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='bestchoice' />
        <BestChoiceSelection {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: '/vitrine',
    component: Vitrine,
    puids: { puid6: 'QUTO_OTHER', puid18: 'other' },
  }, {
    exact: true,
    path: '/catalog',
    component: CatalogLayout,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'main' },
  }, {
    exact: true,
    path: '/:lcv(lcv)',
    component: CatalogLayout,
    puids: { puid6: 'QUTO_LCV', puid18: 'main' },
  }, {
    exact: true,
    path: '/compare',
    component: Compare,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: '/pdd',
    component: Pdd,
    puids: { puid6: 'QUTO_PDD', puid18: 'main' },
  }, {
    exact: true,
    path: '/pdd/marafon',
    component: PddMarafonPage,
    puids: { puid6: 'QUTO_PDD', puid18: 'other' },
  }, {
    exact: true,
    path: '/pdd/exam',
    component: PddExamPage,
    puids: { puid6: 'QUTO_PDD', puid18: 'other' },
  }, {
    exact: true,
    path: '/pdd/tren/:ticketNumber([1-3]?[1-9]|[1-4]0)?', // 40 билетов pdd
    component: PddTrenPage,
    puids: { puid6: 'QUTO_PDD', puid18: 'other' },
  }, {
    exact: true,
    path: '/geo',
    component: Geo,
    puids: { puid6: 'QUTO_OTHER', puid18: 'other' },
  }, {
    exact: true,
    path: `/review/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:review_id(${numericSlug})/(comments)?`,
    component: ReviewLayout,
    puids: { puid6: 'QUTO_OTHER', puid18: 'other' },
  }, {
    exact: true,
    path: `/review/:brand(${basicSlug})?/:model(${basicSlug})?/:generation(${basicSlug})?/:body(${basicSlug})?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='review' />
        <ReviewsForm />
        <Reviews {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_OTHER', puid18: 'other' },
  }, {
    exact: true,
    path: `/inventory/:geo(${basicSlug})?/:brand(${basicSlug})?/:model(${basicSlug})?/:generation(${basicSlug})?`,
    component: OffersLayout,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/inventory/:geo(${basicSlug})/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:slug(${numericSlug})`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='inventory' />
        <Offer {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/dealers/:geo(${basicSlug})?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='dealer' />
        <DealersForm />
        <Dealers {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_DEALERS', puid18: 'main' },
  }, {
    exact: true,
    path: `/dealers/:geo(${basicSlug})?/:brand(${basicSlug})?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='dealer' />
        <DealersForm />
        <Dealers {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_DEALERS', puid18: 'other' },
  }, {
    exact: true,
    path: `${DEALER_URL}/:geo(${basicSlug})/:brand(${basicSlug})?/:dealer_slug(${basicSlug})/:offer_type(used|inventory)?`,
    component: Dealer,
    puids: { puid6: 'QUTO_DEALERS', puid18: 'other' },
  }, {
    exact: true,
    path: MOTO_URL,
    component: MotoCatalogLayout,
    puids: { puid6: 'QUTO_MOTO', puid18: 'main' },
  }, {
    exact: true,
    path: `${MOTO_URL}/compare`,
    component: Compare,
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `${MOTO_URL}/dealers/:geo(${basicSlug})?/:brand(${basicSlug})?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='dealer' />
        <DealersForm />
        <Dealers {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `${MOTO_URL}/inventory/:geo(${basicSlug})/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:slug(${numericSlug})`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='inventory' />
        <Offer {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    path: `${MOTO_URL}/inventory/:geo(${basicSlug})?/:brand(${basicSlug})?/:model(${basicSlug})?/:generation(${basicSlug})?`,
    component: MotoOffersLayout,
    puids: { puid6: 'QUTO_MOTO', puid18: 'main' },
  }, {
    exact: true,
    path: `${MOTO_URL}${DEALER_URL}/:geo(${basicSlug})/:brand(${basicSlug})?/:dealer_slug(${basicSlug})`,
    component: Dealer,
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `${MOTO_URL}/:brand(${basicSlug})/:model(${basicSlug})/:release(${basicSlug})/:suffix(photo|video)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <MotoReleasePhoto {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `${MOTO_URL}/:brand(${basicSlug})?/:model(${basicSlug})?/:release(${basicSlug})?`,
    component: MotoCatalogLayout,
    puids: { puid6: 'QUTO_MOTO', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})`,
    component: CatalogLayout,
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(photo|video)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleasePhoto {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(colors)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleaseColors {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(features)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleaseFeatures {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:modification(${basicSlug})/:property(property)?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <Modification {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:lcv(lcv)/:brand(${basicSlug})/:model(${basicSlug})?/:generation(${basicSlug})?/:body(${basicSlug})?`,
    component: CatalogLayout,
    puids: { puid6: 'QUTO_LCV', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})`,
    component: CatalogLayout,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(photo|video)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleasePhoto {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(colors)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleaseColors {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(features)`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleaseFeatures {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:suffix(features)/:feature_id(${numericSlug})/:comments(comments)?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <ReleaseFeature {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})`,
    component: CatalogLayout,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})/:generation(${basicSlug})/:body(${basicSlug})/:modification(${basicSlug})/:property(property)?`,
    render: props => (
      <Fragment>
        <SecondaryMenu section='catalog' />
        <Modification {...props} />
      </Fragment>
    ),
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  }, {
    exact: true,
    path: `/:brand(${basicSlug})/:model(${basicSlug})?/:generation(${basicSlug})?/:body(${basicSlug})?`,
    component: CatalogLayout,
    puids: { puid6: 'QUTO_CATALOG', puid18: 'other' },
  },
];
