import { Fragment } from 'react';
import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function SpecificationValue({ value, unit }) {
  const isSimple = typeof value === 'string' || typeof value === 'number';
  const isBoolean = typeof value === 'boolean';
  const isObjectValue = value && value.max;

  return (
    <Fragment>
      {value ? (
        <Fragment>
          {isObjectValue && (
            <Fragment>
              {value.min === value.max
                ? `${value.max} ${unit}`
                : `${value.min} - ${value.max} ${unit}`
              }
            </Fragment>
          )}
          {isSimple && <Fragment>{`${value} ${unit}`}</Fragment>}
          {isBoolean && <span className={styles.checked} />}
        </Fragment>
      ) : <MarkdownWrapper inline>&#8212;</MarkdownWrapper>
      }
    </Fragment>
  );
}

SpecificationValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  unit: PropTypes.string,
};
