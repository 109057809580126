import queryString from 'core/libs/query-string';
import cx from 'classnames';
import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Dropdown from 'core/components/Dropdown';
import Link from 'core/components/Link';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import SortIcon from 'site/icons/Sort';

import styles from './index.styl';

const DESC_CHAR = '-';


function SortFilter(props) {
  const {
    theme,
    filters,
    location,
    location: {
      pathname,
      search,
    },
    defaultOrder,
  } = props;

  if (!location || !filters?.length) return null;

  let sortSettings = {
    ...filters[0],
    desc: defaultOrder === 'desc',
  };

  const queryParams = queryString.parse(search);
  const isSortParamValid = queryParams.sort && typeof queryParams.sort === 'string';

  if (isSortParamValid) {
    const sortParamDesc = queryParams.sort.charAt(0) === DESC_CHAR;
    const sortParamSlug = sortParamDesc ? queryParams.sort.slice(1) : queryParams.sort;
    const sortFromParams = filters.find(({ slug }) => slug === sortParamSlug);

    if (sortFromParams) {
      sortSettings = {
        ...sortFromParams,
        desc: sortParamDesc,
      };
    }
  }

  const {
    title: sortTitle,
    desc: sortDesc,
  } = sortSettings;

  const titleNode = (
    <div className={styles.header}>
      <Desktop>Сортировать по {sortTitle}</Desktop>
      <Mobile>по {sortTitle}</Mobile>
      <SortIcon className={styles.sortIcon} />
    </div>
  );

  return (
    <div
      className={cx(
        'sort',
        sortDesc && styles.sortDesc
      )}
    >
      <style jsx>{`
        .header
          color ${theme.colors.primary500}

          :global([id*="$"])
            fill ${theme.colors.primary500}
      `}</style>

      <Dropdown
        anchor={titleNode}
        contentPadding='10px'
        showIcon={false}
        contentTopIndent={0}
        contentPosition='right'
      >
        {filters.map(({ slug, title }) => {
          if (sortSettings.slug === slug) {
            return (
              <div className={styles.item} key={slug}>
                <Link to={`${pathname}?${queryString.stringify({ ...queryParams, sort: `${sortDesc ? '' : DESC_CHAR}${slug}` })}`}>
                  <MarkdownWrapper inline>
                    по ${title} ${sortDesc ? '&#8595;' : '&#8593;'}
                  </MarkdownWrapper>
                </Link>
              </div>
            );
          }

          return (
            <div className={styles.item} key={slug}>
              <Link to={`${pathname}?${queryString.stringify({ ...queryParams, sort: slug })}`}>{`по ${title}`}</Link>
            </div>
          );
        })}
      </Dropdown>
    </div>
  );
}

SortFilter.propTypes = {
  theme: themePropTypes(`{
    colors: {
      primary500,
    }
  }`),
  /**
   * Получаем location не из withRouter, а как свойство пробрасываемое от BaseRoutes.
   * Иначе, попытки получить изменения location внутри текущего компонента не срабатывают.
   * Что-то блокирует (PureComponent, Old contextAPI, etc...) перерисовку компонента на изменение location.search
   * TODO: Попробовать обновить react-router с использованием нового ContextAPI.
   */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
  defaultOrder: PropTypes.oneOf(['asc', 'desc']),
};

SortFilter.defaultProps = {
  defaultOrder: 'desc',
};

export default withTheme(SortFilter);
