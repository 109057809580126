import PropTypes from 'prop-types';
import cx from 'classnames';
import accounting from 'accounting';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import relationshipsPropTypes from 'site/utils/prop-types/relationships';
import modelPropTypes, {
  carNoveltyAttributes,
  carReleaseAttributes,
} from 'site/utils/prop-types/model';
import getThumbnail from 'site/utils/get-thumbnail';

import cardServiceHOC from 'site/components/cardService';

import styles from './index.styl';

const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {});


function CardServiceNewRelease2(props) {
  const {
    theme,
    isMobile,
    content,
  } = props;

  if (!content) return null;

  const {
    attributes: {
      name_for_banner: titleBanner,
      name,
    },
  } = content;

  const {
    release: {
      url,
      short_name: shortName,
      image,
      min_price: minPrice,
      max_price: maxPrice,
    },
  } = relationships(content);

  const cover = getThumbnail(image);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .link
          color ${theme.colors.primary}

          &:visited
            color ${theme.colors.primary}

          &:hover
            color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  const IMAGE_WIDTH = isMobile ? 226 : 400;

  return (
    <Link
      to={url}
      type='secondary'
      className={cx(scope.wrapClassNames('link'), styles.link)}
      data-qa='main-card-new-release'
    >
      <style jsx>{`
      .${styles.shortName}
        font-family ${theme.fonts.text}

      .${styles.name}
        color ${theme.colors.primary500}

      .${styles.titleBanner}
        color ${theme.colors.primary}
        font 14px/18px ${theme.fonts.text}
    `}</style>
      <div className={styles.content}>
        {shortName && (
          <div className={styles.shortName} data-qa='main-card-title-new-release'>
            {shortName}
          </div>
        )}
        {name && <div className={styles.name}>{name}</div>}
        {titleBanner && (
          <div className={styles.titleBanner}>
            <MarkdownWrapper inline>
              {titleBanner}
            </MarkdownWrapper>
          </div>
        )}
        {Boolean(minPrice || maxPrice) && (
          <div className={styles.price}>
            {accounting.formatNumber(minPrice)} — {accounting.formatMoney(maxPrice)}
          </div>
        )}
      </div>
      <div className={styles.image}>
        {cover && (
          <Image
            src={cover}
            width={IMAGE_WIDTH}
          />
        )}
      </div>
      <scope.styles />
    </Link>
  );
}

CardServiceNewRelease2.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Модель данных для карточки */
  content: modelPropTypes(
    carNoveltyAttributes,
    relationshipsPropTypes({
      release: modelPropTypes(carReleaseAttributes),
    })
  ),
  /** @ignore */
  theme: PropTypes.object,
};

const CardWithHOCs = cardServiceHOC(CardServiceNewRelease2);
CardWithHOCs.displayName = 'CardServiceNewRelease2';

export default CardWithHOCs;
export { CardServiceNewRelease2 as StorybookComponent };
