import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import color from 'core/libs/color';
import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import Image from 'core/components/Image';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import Headline from 'site/components/Headline';
import { Indent } from 'site/components/Wrappers/index';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import ArrowRightSm from 'site/icons/ArrowRightSm';

import { CONTENT_AREA, SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';

import Caption from 'site/pages/topic/captions';
import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  {
    image: { versions: {} },
  },
);

function GalleryWrapper(props) {
  const {
    mediaHandler,
    isMediaVisible,
    children,
    content,
    theme,
    isMobile,
  } = props;

  const {
    attributes: {
      headline,
      announce,
      alternative_headline: altHeadline,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <div className={cx(
      styles.wrapper,
      isMobile && !isMediaVisible && '_isMediaNotVisible')}
    >
      <style jsx>{`
        .${styles.wrapper}
          &._isMediaNotVisible
            background ${theme.colors.content}

        .${styles.overlay}
          :global(.desktop) &
            background ${color(theme.colors.content).alpha(0.8)}

        .${styles.image}
          :global(.mobile) &
            &:after
              background linear-gradient(180deg,
                ${color(theme.colors.content).alpha(0)} 40%,
                ${color(theme.colors.content).alpha(1)} 100%
              )

        .${styles.altHeadline}
          color ${theme.colors.primary}
          font-family ${theme.fonts.display}

        .${styles.announce}
          color ${theme.colors.primary800}
          font-family ${theme.fonts.text}
      `}</style>
      {isMediaVisible
        ? <Fragment>{children}</Fragment>
        : (
          <Fragment>
            <div className={styles.image}>
              <Image
                src={cover}
                previewSrc={previewCover}
                maxWidth={CONTENT_AREA}
                aspectRatio={2}
              />
            </div>
            <div className={styles.overlay} />
            <Indent left={horisontal} right={horisontal}>
              <div className={styles.content}>
                <Headline
                  dataQa='lb-topic-header-texts-title'
                >
                  {headline}
                </Headline>
                {altHeadline && (
                  <div className={styles.altHeadline}>
                    <MarkdownWrapper inline>
                      {altHeadline}
                    </MarkdownWrapper>
                  </div>
                )}
                {announce && (
                  <div className={styles.announce}>
                    <MarkdownWrapper inline>
                      {announce}
                    </MarkdownWrapper>
                  </div>
                )}
                <Indent top={isMobile ? 14 : 50}>
                  <ButtonWithIcon
                    icon={ArrowRightSm}
                    iconPosition='right'
                    onClick={mediaHandler}
                    {...isMobile && { minWidth: '100%' }}
                  >
                    <ButtonTextWrap>Начать просмотр</ButtonTextWrap>
                  </ButtonWithIcon>
                </Indent>
                <Indent top={isMobile ? 14 : 20}>
                  <Caption content={content} />
                </Indent>
              </div>
            </Indent>
          </Fragment>
        )
      }
    </div>
  );
}

GalleryWrapper.propTypes = {
  isMobile: PropTypes.bool,
  mediaHandler: PropTypes.func.isRequired,
  isMediaVisible: PropTypes.bool.isRequired,
  children: PropTypes.node,
  content: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      primary800,
    },
  }`),
};

export default withBreakpoint(withTheme(GalleryWrapper));
