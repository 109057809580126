import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import SimilarOffers from 'site/components/SimilarOffers';
import TitleUpperCase from 'site/components/TitleUpperCase';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing2, ListingSpec } from 'site/components/Ads/mobile';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

const relationships = resolveRelationships(['brand'], {}, {});

function ReleaseBoroda(props) {
  const {
    release,
    isMobile,
  } = props;

  const { seo_text: seoText } = release?.attributes || {};

  const brandName = relationships(release).brand.name;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <AdWrapper bottom={vertical}>
      <Indent bottom={vertical} />

      <Desktop><SuperFooter /></Desktop>
      <Mobile><ListingSpec /></Mobile>

      <SimilarOffers />
      {seoText && (
        <Indent bottom={vertical}>
          <TitleUpperCase title='Описание модели' />
          <Indent bottom={15} />
          <MarkdownWrapper inline>
            {seoText}
          </MarkdownWrapper>
        </Indent>
      )}
      <WhereToBuy brandName={brandName} />

      <Indent bottom={vertical} />

      <Mobile><Listing2 /></Mobile>
    </AdWrapper>
  );
}

ReleaseBoroda.propTypes = {
  isMobile: PropTypes.bool,
  release: modelPropTypes(carReleaseAttributes),
};

export default withBreakpoint(ReleaseBoroda);
