import {
  getNextQuestionIndex,
  getPrevQuestionIndex,
} from 'site/utils/pdd';
import resolveRelationships from 'core/utils/relationships';

import { PDD_TICKET_QUESTIONS_COUNT, PDD_EXAM_MAX_MISTAKES } from 'site/constants';

const relationships = resolveRelationships(['category'], {});
const initialAnswers = new Array(PDD_TICKET_QUESTIONS_COUNT).fill(null);


export function init(payload) {
  return {
    answers: [...initialAnswers],
    startTime: Date.now(),
    finishTime: null,
    questionIndex: 0,
    additionalQuestions: [],
    mistakes: [],
    ...payload,
  };
}

export default function reducer(state, action) {
  switch (action.type) {
    case 'saveAnswer': {
      const {
        question,
        answer,
      } = action.payload;

      const {
        answers,
        questionIndex,
        mistakes,
      } = state;

      let newAnswers = [...answers];
      let newMistakes = [...mistakes];

      const isCorrect = question.attributes.correct_answer === answer;

      if (!isCorrect) {
        newMistakes = newMistakes.concat({
          questionIndex,
          category: relationships(question).category.quto_id,
          ticket: question.attributes.ticket,
        });

        if (PDD_EXAM_MAX_MISTAKES >= newMistakes.length && questionIndex < PDD_TICKET_QUESTIONS_COUNT) {
          newAnswers = newAnswers.concat(new Array(5).fill(null));
        }
      }

      newAnswers[questionIndex] = {
        answer,
        isCorrect,
      };

      return {
        ...state,
        mistakes: newMistakes,
        answers: newAnswers,
        questionIndex: getNextQuestionIndex(newAnswers, questionIndex),
      };
    }
    case 'setAdditionalQuestions':
      return {
        ...state,
        additionalQuestions: state.additionalQuestions.concat(action.payload),
      };

    case 'prev': {
      const prevQuestionIndex = getPrevQuestionIndex(state.answers, state.questionIndex);
      if (prevQuestionIndex > -1) {
        return { ...state, questionIndex: prevQuestionIndex };
      }
      return { ...state };
    }
    case 'next': {
      const nextQuestionIndex = getNextQuestionIndex(state.answers, state.questionIndex);
      if (nextQuestionIndex > -1) {
        return { ...state, questionIndex: nextQuestionIndex };
      }
      return { ...state };
    }
    case 'setFinishTime':
      // можно установить только 1 раз. Чтобы не перезаписывать при перезагрузках
      if (state.finishTime) {
        return state;
      }

      return {
        ...state,
        finishTime: action.payload,
      };

    case 'reset':
      return init(action.payload);

    default:
      return { ...state };
  }
}
