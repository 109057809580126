import deepmerge from 'core/libs/deepmerge';
import color from 'core/libs/color';

import bindProps from 'core/components/bindProps';

import { fonts } from './base';

import Logo from 'site/components/Logo';

import { Card1Type2 } from 'site/cards/Card1';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

const LogoSm = bindProps({ type: 5 })(Logo);

export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `400 18px/26px ${fonts.text}`,
      marginTop: '15px',
    },

    body: {
      font: `16px/22px ${fonts.text}`,
    },

    bodySmall: {
      font: `14px/18px ${fonts.text}`,
    },

    h1: {
      font: `bold 24px/26px ${fonts.display}`,
    },

    h2: {
      font: `bold 24px/26px ${fonts.display}`,
    },

    h3: {
      font: `bold 18px/20px ${fonts.display}`,
    },

    h4: {
      font: `bold 16px/18px ${fonts.display}`,
    },

    h5: {
      font: `bold 16px/18px ${fonts.display}`,
    },

    h6: {
      font: `bold 14px/16px ${fonts.text}`,
    },

    caption1: {
      font: `14px/18px ${fonts.text}`,
    },

    caption3: {
      font: `14px/18px ${fonts.text}`,
    },

    quote: {
      font: `16px/22px ${fonts.display}`,
    },

    menu: {
      font: `500 14px/16px ${fonts.text}`,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '15px',
    },
    title: {
      marginBottom: '15px',
    },
    drum: {
      itemWidth: 340,
      spaceBetween: 10,
    },
  };

  const vikontIncut = {
    padding: '0 0 0 12px',
    font: texts.lead.font,
  };

  const scooter = {
    menuLink: {
      textTransform: 'uppercase',
      font: `500 14px/26px ${fonts.text}`,
    },
    logo: {
      width: 118,
      height: 35,
    },
    social: {
      margin: 0,
      size: 50,
    },
  };

  const shapka = {
    logo: {
      Icon: LogoSm,
      width: 72,
      height: 21,
    },
    menu: {
      itemDivider: 'none',
    },
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
  };

  const searchInput = {
    placeholder: 'Поиск по сайту',
  };

  const expert = {
    name: {
      idle: {
        font: `500 14px/20px ${fonts.nuance}`,
      },
      hover: {
        font: `500 14px/20px ${fonts.nuance}`,
      },
    },
    jobTitle: {
      font: `400 11px/18px ${fonts.nuance}`,
    },
    label: {
      font: `700 8px/1 ${fonts.nuance}`,
    },
  };

  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_INDENT_MOBILE,
    },
    block: {
      spacing: VERTICAL_INDENT_MOBILE,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 18px/1.2 ${fonts.display}`,
    },
    name: {
      font: `bold 14px/18px ${fonts.text}`,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `600 22px/27px ${fonts.text}`,
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 18px/20px ${fonts.display}`,
      },
      info: {
        font: `500 13px/18px ${fonts.text}`,
      },
    },
  };


  const errorPage = {
    imgWidth: 190,
    card: Card1Type2,
    popular: {
      padding: '20px',
      header: {
        padding: '20px 0 0 0',
      },
    },
  };

  const pages = {
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      card: null,
    },
    development: {
      ...errorPage,
      card: null,
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `800 24px/30px ${fonts.display}`,
    },
    subtitle: {
      font: `300 20px/30px ${fonts.display}`,
    },
    divider: {
      margin: '15px calc(100% - 300px) 15px 0',
      background: color(atoms.colors.primary).alpha(0.15).string(),
    },
  };

  const gameCompare = {
    leaderBoard: {
      header: {
        font: `700 12px/17px ${fonts.display}`,
      },
    },
    share: {
      showBorder: true,
      marginTop: 0,
    },
    winner: {
      viewBox: '0 0 380 400',
    },
  };

  const about = {
    headline: {
      font: `bold 24px/26px ${fonts.text}`,
    },
    title2: {
      font: `bold 24px/26px ${fonts.text}`,
    },
  };

  return deepmerge({
    controls: {
      vikontReadMore,
      vikontIncut,
      scooter,
      shapka,
      searchInput,
      expert,
      topicFooter,
      smartTeaser,
      commentsPreview,
      storyWidgetsBaseCard,
      topicHeaderTexts,
      gameCompare,
      about,
    },
    pages,
    layout,
    texts,
  }, atoms);
}
