import PropTypes from 'prop-types';
import cx from 'classnames';
import accounting from 'accounting';

import { Mobile, Desktop } from 'core/components/breakpoint';
import cardServiceHOC from 'site/components/cardService';

import Link from 'core/components/Link';

import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import Battery from 'site/components/Battery/index';
import Hint from 'site/components/Hint';
import ButtonCompare from 'site/components/ButtonCompare';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

const relationships = resolveRelationships(['completion'], {}, {});


function CardServiceCompletion(props) {
  const {
    theme,
    content,
    isMobile,
    position,
  } = props;

  const {
    id,
    attributes: {
      url,
      battery: {
        total: batteryTotal,
      },
      prices,
      usage,
    },
    type,
  } = content;

  const {
    price,
    price_special: priceSpecial,
    year,
  } = prices[0] || {};

  const isArchive = usage === 'archive' && year;
  const notForSale = usage === 'archive' && !year;

  const {
    completion: {
      title,
    },
  } = relationships(content);

  const cost = priceSpecial || price;

  return (
    <div className={styles.completion}>
      <style jsx>{`
        .${styles.completion}
          border-top ${position ? 1 : 0}px solid ${theme.colors.dividerLight}
      `}</style>
      <div className={styles.title}>
        <Link to={`${url}/property`}>{title}</Link>
        {isArchive && ' (Cнята с производства)'}
        {notForSale && ' (Нет в продаже)'}
      </div>
      <div className={styles.column}>
        <div className={styles.battery}>
          <Hint description='Заряженность определяется по наличию в базе ключевых опций, отобранных нашими экспертами в четырех категориях'>
            <Battery
              type={isMobile ? 5 : 6}
              value={batteryTotal}
            />
          </Hint>
        </div>
        {!!cost && !isArchive && (
          <div className={styles.prices}>
            <Desktop>
              {priceSpecial && (
                <div className={cx(styles.price, styles.oldPrice)}>
                  {accounting.formatMoney(price)}
                </div>
              )}
            </Desktop>
            <div className={styles.price}>
              <Mobile>{accounting.formatMoney(cost)}</Mobile>
              <Desktop>от {accounting.formatMoney(cost)}</Desktop>
            </div>
          </div>
        )}
        <ButtonCompare
          modelId={id}
          transportType={type}
          round
        />
      </div>
    </div>
  );
}

CardServiceCompletion.defaultProps = {
  position: 0,
};

CardServiceCompletion.propTypes = {
  /** Контент карточки, соответствующий модели `carModificationAttributes` */
  content: modelPropTypes(carModificationAttributes),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Позиция карточки */
  position: PropTypes.number,
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      divider,
    },
  }`),
};

const CardWithHOCs = cardServiceHOC(CardServiceCompletion);
CardWithHOCs.displayName = 'CardServiceCompletion';

export { CardServiceCompletion as StorybookComponent };
export default CardWithHOCs;
