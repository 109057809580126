import { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import H5 from 'core/components/H5';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import OptionModal from 'site/components/OptionModal';
import OptionRadio from 'site/components/OptionRadio';
import { Indent } from 'site/components/Wrappers';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import styles from './index.styl';

import mapTypes from './types';

import { getDependencies } from 'site/utils/configurator';

function getPriceWithDependencies(option, allOptions, allPackages) {
  if (!option) return 0;
  let currentPrice = option.price;
  const includeDependencies = getDependencies(option.dependencies, 'include');
  const requireDependencies = getDependencies(option.dependencies, 'require');
  const excludeDependencies = getDependencies(option.dependencies, 'exclude');
  const positiveDependencies = [...includeDependencies, ...requireDependencies]
    .filter(dependency => dependency.sum_dependencies_price);

  // добавляем зависимости type=include|require в стоимость
  if (positiveDependencies && positiveDependencies.length > 0) {
    currentPrice += positiveDependencies.reduce((sum, { child }) => {
      const dependencyOption = allOptions[child] || allPackages[child];

      if (dependencyOption) {
        return sum + dependencyOption.price;
      }
      return sum;
    }, 0);
  }

  // удаляем зависимости type=exclude из стоимости
  if (excludeDependencies && excludeDependencies.length > 0) {
    currentPrice -= excludeDependencies.reduce((sum, { child }) => {
      const dependencyOption = allOptions[child] || allPackages[child];
      if (dependencyOption) {
        return sum + dependencyOption.price;
      }
      return sum;
    }, 0);
  }

  return currentPrice;
}

/**
 * Здесь обрабатываются группы радио-опций, такие как:
 * цвет, обивка салона, декоративные вставки и др.
 */
function OptionRadioGroup({ modificationId, type, theme, subgroup }) {
  const {
    name,
    options: radioOptions,
    id: subgroupId,
  } = subgroup || {};

  const {
    withoutNames,
    compactView,
  } = mapTypes[type] || {};

  const {
    getConfiguration,
    ...configurations
  } = useContext(ConfiguratorContext);

  const {
    options: allOptions,
    packages: allPackages,
    configuration = {},
  } = configurations[modificationId] || {};

  const {
    installed_options: installedOptions = [],
  } = configuration;

  const [opened, toggleModal] = useState(false);
  /**
   * Установленная в конфигураторе радио-опция, на уровне redisDB.
   */
  const [installedOption, setInstalledOption] = useState(radioOptions[0]);
  /**
   * Текущая опция, но не обязательно установленная.
   * Например, при переключении радио в попап, опция становится выбранной, цена в попапе меняется.
   * При это она еще не установлена.
   */
  const [currentOption, setCurrentOption] = useState(radioOptions[0]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [diffPrice, setDiffPrice] = useState(0);

  const toggleModalCallback = useCallback(() => toggleModal(!opened), [opened]);

  const updateConfiguration = useCallback(() => {
    if (!currentOption) return;

    const isInstalled = !!installedOptions.find(({ id }) => id === currentOption.id);

    getConfiguration(modificationId, {
      option_id: currentOption.id,
      is_install_option: !isInstalled,
    });
  }, [currentOption, getConfiguration, modificationId, installedOptions]);

  useEffect(() => {
    const option = installedOptions
      .find(({ id: localId }) => ~radioOptions.findIndex(({ id }) => id === localId));

    if (!option) return;

    const newCurrentOption = allOptions[option.id] || allPackages[option.id];

    if (!newCurrentOption) return;

    /**
     * По умолчанию опция установленная в попап, равна опции установленной в конфигураторе.
     */
    setCurrentOption(newCurrentOption);
    setInstalledOption(newCurrentOption);
  }, [allOptions, allPackages, installedOptions, radioOptions]);


  useEffect(() => {
    if (!configuration.price) return;

    const isInstalled = !!installedOptions.find(({ id }) => id === currentOption.id);

    const currentPriceWithDependencies = getPriceWithDependencies(currentOption, allOptions, allPackages);

    if (isInstalled) {
      setTotalPrice(configuration.price - currentPriceWithDependencies);
    } else {
      setTotalPrice(configuration.price + currentPriceWithDependencies);
    }
    setDiffPrice(currentPriceWithDependencies);
  }, [allOptions, allPackages, configuration, currentOption, installedOptions]);

  const justOneSimpleRadioOption = radioOptions.length === 1
    && radioOptions.every(group => group.dependencies.length === 0);

  if (!currentOption) return null;

  return (
    <div
      className={cx(
        'optionRadioGroup',
        compactView && styles.compactView,
        justOneSimpleRadioOption && styles.justOneSimpleRadioOption,
      )}
    >
      <style jsx>{`
        .${styles.current}
          color ${theme.colors.blue1}

          .${styles.justOneSimpleRadioOption} &
            color ${theme.colors.primary}
      `}</style>
      <OptionModal
        isOpen={opened}
        toggleModal={toggleModalCallback}
        diffPrice={diffPrice}
        totalPrice={totalPrice}
        handleClose={toggleModalCallback}
        handleAccept={() => {
          toggleModalCallback();
          updateConfiguration();
        }}
      >
        <H5>{name}</H5>
        <Indent top={15}>
          {radioOptions.map(option => {
            if (!option) return <MarkdownWrapper inline>&#8212;</MarkdownWrapper>;

            return (
              <OptionRadio
                key={option.id}
                name={`subgroup_${subgroupId}`}
                defaultChecked={currentOption.id === option.id}
                option={option}
                onChange={setCurrentOption}
                modificationId={modificationId}
              />
            );
          })}
        </Indent>
      </OptionModal>
      {!withoutNames && <span>{name}: </span>}
      <span
        className={styles.current}
        {...!justOneSimpleRadioOption && { onClick: toggleModalCallback }}
      >{installedOption.name}</span>
    </div>
  );
}

OptionRadioGroup.propTypes = {
  type: PropTypes.oneOf([1]),
  subgroup: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      blue1,
    }
  }`),
  modificationId: PropTypes.string,
};

export default withTheme(OptionRadioGroup);
